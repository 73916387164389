var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table-simple',{attrs:{"responsive":""}},[_c('b-tbody',[_c('b-tr',[_c('b-th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("RECURSOS DE APOYO A LA DOCENCIA ")])],1),_vm._l((_vm.supportResourceTypesFilter),function(resource_type){return _c('b-tr',{key:`resource_type-${resource_type.id}`},[_c('b-td',{staticStyle:{"width":"25%"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('strong',{staticClass:"flex-grow-1 text-center"},[_vm._v(" "+_vm._s(resource_type.name.toUpperCase())+": ")]),(
              _vm.allows_crud &&
              _vm.oldMatter &&
              !_vm.oldMatter.is_closed &&
              _vm.isClosedOldMatterPartial(resource_type)
            )?_c('div',[_c('ButtonMedium',{attrs:{"tooltip_text":`Agregue los Recursos por separado`,"click_button":() =>
                  _vm.$bvModal.show(
                    `create-resource-modal-${resource_type.id}-${_vm.matter_form_view}`
                  ),"text_button":``,"custom_class":`mt-1 mb-1 mr-1 square-button`,"icon":'plus',"variant":'primary'}}),_c('b-modal',{attrs:{"id":`create-resource-modal-${resource_type.id}-${_vm.matter_form_view}`,"title":"Crear Recurso","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Type":resource_type},on:{"save":_vm.createSupportResource,"close":function($event){return _vm.closeModal(resource_type.id)}}})],1)],1):_vm._e()])]),_c('b-td',[_c('div',_vm._l((_vm.supportResourcesSort.filter(
              (r) => r.type == resource_type.id
            )),function(resource){return _c('div',{key:`resource-${resource.id}`,staticClass:"border-resource"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"my-1 p-1 w-100 rich-text-content support-resource-content",domProps:{"innerHTML":_vm._s(resource.description)}}),(
                  _vm.allows_crud &&
                  _vm.oldMatter &&
                  !_vm.oldMatter.is_closed &&
                  _vm.isClosedOldMatterPartial(resource_type)
                )?_c('div',{staticClass:"container-btn-resources"},[_c('div',{staticClass:"d-flex p-1"},[_c('ButtonSmall',{class:`w-30`,attrs:{"tabindex":"-1","tooltip_text":`Editar`,"click_button":() =>
                        _vm.$bvModal.show(
                          `edit-resource-modal-${resource.id}-${_vm.matter_form_view}`
                        ),"icon":'square'}}),_c('ButtonSmall',{attrs:{"tabindex":"-1","click_button":() =>
                        _vm.deleteSupportResource(resource.id, resource_type.name),"tooltip_text":`Eliminar`,"icon":'trash'}})],1),_c('b-modal',{attrs:{"id":`edit-resource-modal-${resource.id}-${_vm.matter_form_view}`,"title":"Editar Recurso de Apoyo a la Docencia","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_vm._v(" Copie y edite los Recursos por separado "),_c('NewRichTextEditor',{attrs:{"Object":resource,"Text":resource.description},on:{"save":_vm.updateSupportResource,"close":function($event){return _vm.closeModal(resource.id)}}})],1)],1):_vm._e()])])}),0)])],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }